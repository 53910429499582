import React from 'react'

const SliderInfo = ({ info, i }) => {
    return (
        <div className={`sliderInfo sliderInfo${i + 1}`} style={{ textTransform: "capitalize" }}>
            <h1>{info.title}</h1>
            <div className="keywords">
                {
                    info.keywords.map((keyword) => {
                        return (
                            <span key={keyword}>{keyword}</span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SliderInfo