import React from 'react'
import "./index.css"
import { brainsUniversity, introIMG, introIMG2 } from './assets/assets';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"
import { PiFacebookLogoDuotone, PiInstagramLogoDuotone, PiTwitterLogoDuotone, PiPhoneCallDuotone } from "react-icons/pi"
import Carousel from "nuka-carousel"
import SliderInfo from './components/SliderInfo';

const App = () => {
    const introIMGs = [introIMG, introIMG2];
    const params = {
        renderCenterLeftControls: ({ previousSlide }) => (
            <button onClick={previousSlide}>
                <MdNavigateBefore className="leftRightBtn" />
            </button>
        ),
        renderCenterRightControls: ({ nextSlide }) => (
            <button onClick={nextSlide}>
                <MdNavigateNext className="leftRightBtn" />
            </button>
        ),
        wrapAround: true,
        autoplay: true,
        autoplayInterval: 5000
    }

    const info = [
        {
            title: "School of health sciences (SHS)",
            keywords: ["Nursing", "Midwifery", "Physiotherapy", "Dental Therapy", "Pharmacy technology", "Health Care Management", "Medical Laboratory sciences"]
        },
        {
            title: "school of agriculture and food technology",
            keywords: ["Aquaculture", "Crop-production", "Animal Production", "Agro Pastoral Adviser", "Nutrition and Dietics", "Farm Managment", "Agricultural Engineering", "Food processing Technology"]
        }
    ]

    return (
        <div className="container">
            <div className="introduction">
                <div className="brains-info">
                    <img src={brainsUniversity} alt="brains-university" />
                    <h1>Stay Tuned for Our website</h1>
                    <p className="p-1">🌟 www.thebrainsuniversity.org 🌟</p>
                    <div className="brains-detail-div">
                        <div className="social">
                            <span>@thebrainsuniversity</span>
                            <div className="social">
                                <PiFacebookLogoDuotone className='icon' />
                                <PiInstagramLogoDuotone className='icon' />
                                <PiTwitterLogoDuotone className='icon' />
                            </div>
                        </div>
                        <span className='vertical-line'></span>
                        <div className="phone">
                            <PiPhoneCallDuotone className="icon" />
                            <div>
                                <p>(+237) 673-685-611</p>
                                <p>(+237) 696-516-021</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intro-img">
                    <Carousel {...params} style={{ borderRadius: "20px" }}>
                        {
                            introIMGs.map((img, i) => {
                                return (
                                    <img
                                        key={i}
                                        src={img}
                                        alt="brains-community"
                                    />
                                )
                            })
                        }
                        {
                            info.map((info, i) => {
                                return <SliderInfo key={i} info={info} i={i} />
                            })
                        }
                    </Carousel>
                </div>
            </div>

            <div className="footer">
                <div className="footer-info">
                    <p><span style={{ fontWeight: "bold" }}>Location: </span>Immeuble Auto22 BP Cite entree Camrail-Douala / Campus B Bonaberi Douala</p>
                    <h2>thebrainsuniversity@gmail.com</h2>
                </div>
            </div>
        </div>
    )
}

export default App